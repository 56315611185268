export const jobsList = [
    {
        title: "Technology Intern",
        company: "FLIP National",
        startDate: "May 2024",
        endDate: "August 2024",
        description: "[insert paragraph or two, longer version of resume]"
    },
    {
        title: "Financial Aid Office Assistant",
        company: "Bowdoin College",
        startDate: "August 2023",
        endDate: "Current",
        description: "[insert paragraph or two, longer version of resume]"
    },
    {
        title: "Summer Library Circulation and Projects Assistant",
        company: "Bowdoin College",
        startDate: "May 2023",
        endDate: "August 2023",
        description: "[insert paragraph or two, longer version of resume]"
    },
]