import React from "react";
import {PageWrapper} from "../pageSections/PageWrapper";
import Job from "../components/Job";
import {jobsList} from "../utils/ExperiencesInfo"

//import {Section} from "../pageSections/Section";

const Experience: React.FC = () => {
  return (
    <PageWrapper>
      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      <Job title={jobsList[0].title} company={jobsList[0].company} startDate={jobsList[0].startDate} endDate={jobsList[0].endDate} description={jobsList[0].description}></Job>
      <Job title={jobsList[1].title} company={jobsList[1].company} startDate={jobsList[1].startDate} endDate={jobsList[1].endDate} description={jobsList[1].description}></Job>
      <Job title={jobsList[2].title} company={jobsList[2].company} startDate={jobsList[2].startDate} endDate={jobsList[2].endDate} description={jobsList[2].description}></Job>

      
    </PageWrapper>
    );
  };
  
  export default Experience;